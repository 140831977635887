import { MsalProvider } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { Provider as ReduxProvider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { publicClientApplication } from "src/services/api/initMSAL";
import MainRoutes from "src/routes";

import { store } from "./store";

const App = (): JSX.Element => {
  return (
    <ReduxProvider store={store}>
      <MsalProvider instance={publicClientApplication}>
        <CssBaseline />
        <ToastContainer position="top-right" theme="colored" />
        <MainRoutes />
      </MsalProvider>
    </ReduxProvider>
  );
};

export default App;
